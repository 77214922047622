import XCircle from "@/icons/x-circle.svg";
import FacebookWithColor from "@/icons/facebook-login.svg";
import WhiteCheckbox from "@/icons/white-check-mark.svg";
import ActiveCheckbox from "@/icons/active-checkbox.svg";

import AddressEdit from "@/icons/address-edit.svg";
import AddressDelete from "@/icons/address-delete.svg";

import Facebook from "@/icons/social-share/facebook.svg";
import Twitter from "@/icons/social-share/twitter.svg";
import Tiktok from "@/icons/tiktok.svg";
import YoutubeWithColor from "@/icons/youtubeWithColor.svg";
import Instagram from "@/icons/social-share/instagram1.svg";
import Direction from "@/icons/traffic-sign.svg";
import Wishlist from "@/icons/wishlist.svg";
import Cart from "@/icons/cart.svg";
import Account from "@/icons/account.svg";
import Styles from "./icon.module.css";
import Sort from "@/icons/sort.svg";
import StarCelebration from "@/icons/star-celebration.svg";
import Filter from "@/icons/filter.svg";
import PrevLabel from "@/icons/prev-label.svg";
import NextLabel from "@/icons/next-label.svg";
import CashOnDelivery from "@/icons/cash.svg";
import BKash from "@/icons/bKash.svg";
import Map from "@/icons/footer-map.svg";

import Amex from "@/icons/amex.svg";
import Remove from "@/icons/remove-icon.svg";
import Mastercard from "@/icons/mastercard.svg";
import Nagad from "@/icons/nagad.svg";
import SSLCommerce from "@/icons/sslcommerz.svg";
import classNames from "classnames";
import StarFill from "@/icons/active-star.svg";
import StarHalfFill from "@/icons/half-active-star.svg";
import Star from "@/icons/in-active-star.svg";
import Plus from "@/icons/plus-icon.svg";
import Minus from "@/icons/minus-icon.svg";
import ContactEmail from "@/icons/contact-email.svg";
import ContactLocation from "@/icons/contact-location.svg";
import ContactPhone from "@/icons/contact-phone.svg";
import Cross from "@/icons/recent-search-delete-icon.svg";
import CatPaw from "@/icons/cat-paw.svg";
import CatPawBg from "@/icons/cat-paw-bg.svg";
import ShoppingCart from "@/icons/shopping-cart-white.svg";
import Phone from "@/icons/footer-phone.svg";
import Email from "@/icons/footer-mail.svg";
import Info from "@/icons/info.svg";
import DownArrow from "@/icons/pet-tag/down-arrow.svg";
import FeaturesLeftArrow from "@/icons/pet-tag/features-left-arrow.svg";
import FeaturesRightArrow from "@/icons/pet-tag/features-right-arrow.svg";
import QR from "@/icons/pet-tag/qr.svg";
import NFC from "@/icons/pet-tag/nfc.svg";
import WaterProof from "@/icons/pet-tag/waterproof.svg";
import Battery from "@/icons/pet-tag/battery.svg";
import Compatibility from "@/icons/pet-tag/compatibility.svg";
import Android from "@/icons/pet-tag/android.svg";
import Paw from "@/icons/pet-tag/paw.svg";
import Apple from "@/icons/pet-tag/apple.svg";
import FacebookWhite from "@/icons/facebook-logo.svg";
import TwitterWhite from "@/icons/twitter-logo.svg";
import LinkedinWhite from "@/icons/linkedin-logo.svg";
import InstagramWhite from "@/icons/instagram-logo.svg";

import LocationFilled from "@/icons/location_filled.svg";
import PhoneFilled from "@/icons/phone-filled.svg";
import EmailFilled from "@/icons/email_filled.svg";

import Basket from "@/icons/basket.svg";
import ArrowRight from "@/icons/arrow-right.svg";
import TickBgGreen from "@/icons/pet-tag/tick-bg-green.svg";

export function XCircleIcon({ className }) {
  return <XCircle className={`${className} h-[1rem] w-[1rem]`} />;
}

export function RemoveIcon({ className }) {
  return <Remove className={`${className} h-[1rem] w-[1rem]`} />;
}

export function CatPawIcon({ className }) {
  return <CatPaw className={`${className} h-[1rem] w-[1rem]`} />;
}
export function CatPawBgIcon({ className }) {
  return <CatPawBg className={`${className} h-[1rem] w-[1rem]`} />;
}

export function FacebookIcon({ className }) {
  return (
    <Facebook
      className={`${className} text-grayD4 hover:text-primary hover:scale-110 transition-all duration-150`}
    />
  );
}

export function FacebookWithColorIcon({ className }) {
  return (
    <FacebookWithColor
      className={`${className} text-grayD4 hover:text-primary hover:scale-110 transition-all duration-150`}
    />
  );
}

export function ActiveCheckboxIcon({ className }) {
  return <ActiveCheckbox className={`${className}`} />;
}

export function WhiteCheckMarkicon({ className }) {
  return <WhiteCheckbox className={`${className}`} />;
}

export function AddressEditIcon({ className }) {
  return <AddressEdit className={`${className} h-[1rem] w-[1rem]`} />;
}

export function AddressDeleteIcon({ className }) {
  return <AddressDelete className={`${className} h-[1rem] w-[1rem]`} />;
}

export function MapIcon({ className }) {
  return <Map className={`${className}`} />;
}

export function TwitterIcon({ className }) {
  return (
    <Twitter
      className={`${className} text-grayD4 hover:text-primary hover:scale-110 transition-all duration-150`}
    />
  );
}

export function TiktokIcon({ className }) {
  return (
    <Tiktok
      className={`${className} text-grayD4 hover:text-primary hover:scale-110 transition-all duration-150`}
    />
  );
}

export function InstagramIcon({ className }) {
  return (
    <Instagram
      className={`${className} w-8 h-8  hover:scale-110 transition-all duration-150`}
    />
  );
}

export function DirectionIcon({ className }) {
  return <Direction className={`${className}`} />;
}

export function WishlistIcon({ className }) {
  return (
    <Wishlist
      className={`${className} h-[2.125rem] w-[2.125rem] lg:h-[2.625rem] lg:w-[2.625rem] text-white hover:text-primary-50`}
    />
  );
}

export function CartIcon({ className }) {
  return (
    <Cart
      className={`${className} h-[2.125rem] w-[2.125rem] lg:h-[2.625rem] lg:w-[2.625rem] text-white hover:text-primary-50`}
    />
  );
}

export function ContactEmailIcon({ className }) {
  return <ContactEmail className={`${className} h-[1.313rem] w-[1.4rem]`} />;
}
export function ContactPhoneIcon({ className }) {
  return <ContactPhone className={`${className} h-[1.313rem] w-[1.5rem]`} />;
}

export function ContactLocationIcon({ className }) {
  return <ContactLocation className={`${className} h-[1.313rem] w-[1.4rem]`} />;
}

export function AccountIcon({ className }) {
  return (
    <Account
      className={`${className} ${Styles.account_icon} h-[2.125rem] w-[2.125rem] lg:h-[2.625rem] lg:w-[2.625rem]`}
    />
  );
}

export function YoutubeWithColorIcon({ className }) {
  return (
    <YoutubeWithColor
      className={`${className} text-grayD4 hover:text-primary hover:scale-110 transition-all duration-150`}
    />
  );
}

export function SortIcon({ className }) {
  return <Sort className={`${className} h-[1rem] w-[1rem]`} />;
}

export function StarCelebrationIcon({ className }) {
  return <StarCelebration className={`${className} h-[1rem] w-[1.063rem]`} />;
}

export function FilterIcon({ className }) {
  return <Filter className={`${className} h-[1rem] w-[1rem]`} />;
}

export function PrevLabelIcon({ className, isDisable }) {
  return (
    <PrevLabel
      className={classNames(
        {
          "text-disable-color": isDisable,
          "text-grayD2": !isDisable,
        },
        `${className}`
      )}
    />
  );
}

export function NextLabelIcon({ className, isDisable }) {
  return (
    <NextLabel
      className={classNames(
        {
          "text-disable-color": isDisable,
          "text-grayD2": !isDisable,
        },
        `${className}`
      )}
    />
  );
}

export function CashOnDeliveryIcon({ className }) {
  return (
    <CashOnDelivery className={`${className} w-[1.625rem] h-[1.125rem]`} />
  );
}

export function BKashIcon({ className }) {
  return <BKash className={`${className}`} />;
}

export function StarFillIcon({ className }) {
  return <StarFill className={`${className} h-[1rem] w-[1.063rem]`} />;
}

export function StarHalfFillIcon({ className }) {
  return <StarHalfFill className={`${className} h-[1rem] w-[1.063rem]`} />;
}

export function StarIcon({ className }) {
  return <Star className={`${className} h-[1rem] w-[1.063rem]`} />;
}

export function PlusIcon({ className }) {
  return <Plus className={`${className}`} />;
}

export function MinusIcon({ className }) {
  return <Minus className={`${className}`} />;
}

export function AmexIcon({ className }) {
  return <Amex className={`${className}`} />;
}

export function MastercardIcon({ className }) {
  return <Mastercard className={`${className}`} />;
}

export function NagadIcon({ className }) {
  return <Nagad className={`${className}`} />;
}

export function SSLCommerceIcon({ className }) {
  return <SSLCommerce className={` w-[8.375rem] h-[1.938rem] ${className}`} />;
}

export function CrossIcon({ className }) {
  return <Cross className={`w-[0.6rem] h-[1rem] ${className}`} />;
}

export function ShoppingCartIcon({ className }) {
  return <ShoppingCart className={`${className}`} />;
}

export function PhoneIcon({ className }) {
  return <Phone className={`${className}`} />;
}

export function EmailIcon({ className }) {
  return <Email className={`${className}`} />;
}

export function InfoIcon({ className }) {
  return <Info className={`${className} w-3 h-3`} />;
}

export function FacebookWhiteIcon({ className }) {
  return <FacebookWhite className={`${className}`} />;
}

export function TwitterWhiteIcon({ className }) {
  return <TwitterWhite className={`${className}`} />;
}

export function LinkedinWhiteIcon({ className }) {
  return <LinkedinWhite className={`${className}`} />;
}

export function InstagramWhiteIcon({ className }) {
  return <InstagramWhite className={`${className}`} />;
}

export function PhoneFilledIcon({ className }) {
  return <PhoneFilled className={`${className}`} />;
}

export function EmailFilledIcon({ className }) {
  return <EmailFilled className={`${className}`} />;
}

export function LocationFilledIcon({ className }) {
  return <LocationFilled className={`${className}`} />;
}

export function BasketIcon({ className }) {
  return <Basket className={`${className} text-[#050426]`} />;
}

export function ArrowRightIcon({ className }) {
  return <ArrowRight className={`${className} text-[#050426]`} />;
}

export function DownloadIcon() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 4V20H17V12H19V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21V3C5 2.44772 5.44772 2 6 2H13V4H7ZM19 6H22L18 10L14 6H17V2H19V6ZM13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18Z"></path>
    </svg>
  );
}

export function DownArrowIcon({ className }) {
  return <DownArrow className={`${className} w-[1.5rem] h-[1.5rem]`} />;
}

export function FeaturesLeftArrowIcon({ className }) {
  return <FeaturesLeftArrow className={`${className}`} />;
}

export function FeaturesRightArrowIcon({ className }) {
  return <FeaturesRightArrow className={`${className}`} />;
}

export function QRIcon({ className }) {
  return <QR className={`${className}`} />;
}

export function NFCIcon({ className }) {
  return <NFC className={`${className}`} />;
}

export function WaterProofIcon({ className }) {
  return <WaterProof className={`${className}`} />;
}

export function BatteryIcon({ className }) {
  return <Battery className={`${className}`} />;
}

export function CompatibilityIcon({ className }) {
  return <Compatibility className={`${className}`} />;
}

export function AndroidIcon({ className }) {
  return <Android className={`${className}`} />;
}

export function PawIcon({ className }) {
  return <Paw className={`${className}`} />;
}

export function AppleIcon({ className }) {
  return <Apple className={`${className}`} />;
}

export function TickBgGreenIcon({ className }) {
  return <TickBgGreen className={`${className}`} />;
}

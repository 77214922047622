import Topbar from "./topbar";
import NavBar from "./Navbar/NavBar";
import { Menubar } from "./menubar";
import InfoBar from "./infobar/InfoBar";
import { useDispatch } from "react-redux";
import { updateCartItemCount } from "@/store/feature/cartSlice";
import { updateWishlistItemCount } from "@/store/feature/wishlistSlice";
import { fetchAllBrands } from "repo/brand";
import { useEffect, useState } from "react";
import { useFetchMainMenuList } from "repo/main-menu";
import menuData from "@/utils/constants/mainMenu.constants.json";
import { useGetNotification } from "@/api/client/notification";
import {
  updatePromotionalData,
  updatePromotionalModalStatus,
} from "@/store/feature/promotionalSlice ";
import { checkPromotionalBannerStatus } from "@/utils/helpers/promotionalBanner";

function NavBarWrapper() {
  const [menuOptions, setMenuOptions] = useState(menuData);
  const [brandData, setBrandData] = useState([]);
  const [topMenuData, setTopMenuData] = useState();

  const dispatch = useDispatch();

  useFetchMainMenuList({
    onSuccess: (data) => {
      setMenuOptions(data.mainMenuData);
      setTopMenuData(data.topBannerInfo);
      handlePromotionalModal(data.promotionalInfo);
    },
  });

  useGetNotification({
    onSuccess: (notification) => {
      dispatch(updateCartItemCount(notification.cart_product_count));
      dispatch(updateWishlistItemCount(notification.wishlist_product_count));
    },
  });

  const handlePromotionalModal = (data) => {
    if (!checkPromotionalBannerStatus() && data.status) {
      dispatch(updatePromotionalModalStatus(data.status));
      dispatch(updatePromotionalData(data));
    }
  };

  useEffect(() => {
    getBrandData();
  }, []);

  async function getBrandData() {
    const resData = await fetchAllBrands();
    if (resData) {
      setBrandData(resData.data?.brands);
    }
  }

  return (
    <>
      <div className="shadow-[0_6px_28px_-0px_rgba(27,45,73,0.06)] md:shadow-none	top-0">
        {topMenuData && <Topbar topMenuData={topMenuData} />}
        <InfoBar />
        <NavBar menuOptions={menuOptions} />
      </div>

      <Menubar menuOptions={menuOptions} brandData={brandData} />
    </>
  );
}

export default NavBarWrapper;
